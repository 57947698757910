import * as React from "react"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "../../components/ui/base/typography"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Section from "../../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import { getCalendar, getLocalizedLinkToMonth } from "../../util/tour"
import SessionCard from "../../components/ui/base/SessionCard"
import { useTranslation } from "react-i18next"
import Link from "../../components/ui/base/Link"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"

const CalendarYearPage = pageData => {
  const { t, i18n: l } = useTranslation()
  const [months, setMonths] = useState(0)
  const data = useStaticQuery(query)
  const tours = data.allStrapiTour.edges
  const staticMonths = getCalendar(tours)
  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/tours`)
      .then(response => response.json())
      .then(resultData => {
        setMonths(getCalendar(resultData, pageData.pageContext.year))
      })
  }, [])

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        language={l.language}
        title={`Viaggi Fotografici ${pageData.pageContext.year}`}
        description={`Scopri quali viaggi fotografici abbiamo in calendario per il ${pageData.pageContext.year}. Parti con altre persone come te, scatta fotografie da invidia. Prenota Ora `}
      />
      <Header
        size={"compact"}
        videoURL={
          "/media/videos/it/viaggi-fotografici-video/weshoot-viaggi-fotografici.mp4"
        }
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Viaggi Fotografici {pageData.pageContext.year}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: "/viaggi-fotografici/",
                    },
                    {
                      name: "Calendario",
                      path: "/viaggi-fotografici/calendario/",
                    },
                    {
                      name: `${pageData.pageContext.year}`,
                      path: `/viaggi-fotografici/calendario/${pageData.pageContext.year}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        id={"sessions"}
        className={classnames([styles.next_departures])}
        title={t("templates.calendar.calendar.nextDepartures.title")}
        variant={"grey"}
        subtitle={t("templates.calendar.calendar.nextDepartures.description")}
      >
        {Object.keys(months).length === 0
          ? Object.keys(staticMonths).map((key, i) => {
              let month = staticMonths[key]
              return (
                <div key={key}>
                  <div className={classnames([styles.monthWrapper])}>
                    <h3 className={classnames([styles.title])}>
                      {`${month.month} ${month.year}`}
                    </h3>
                    <div className={classnames([styles.number])}>
                      <Link
                        to={getLocalizedLinkToMonth(l, month.year, month.month)}
                      >
                        {t("templates.calendar.calendar.table.header.showAll", {
                          count: month.tours.length,
                          month: month.month,
                          year: month.year,
                        })}
                      </Link>
                    </div>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={session.id}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                        skeleton={false}
                      />
                    )
                  })}
                </div>
              )
            })
          : Object.keys(months).map((key, i) => {
              let month = months[key]
              return (
                <div key={key}>
                  <div className={classnames([styles.monthWrapper])}>
                    <h3 className={classnames([styles.title])}>
                      {`${month.month} ${month.year}`}
                    </h3>
                    <div className={classnames([styles.number])}>
                      <Link
                        to={getLocalizedLinkToMonth(l, month.year, month.month)}
                      >
                        {t("templates.calendar.calendar.table.header.showAll", {
                          count: month.tours.length,
                          month: month.month,
                          year: month.year,
                        })}
                      </Link>
                    </div>
                  </div>
                  {month.tours?.map(session => {
                    return (
                      <SessionCard
                        key={session.id}
                        session={session.session}
                        tour={session.tour}
                        directBooking={false}
                        skeleton={false}
                      />
                    )
                  })}
                </div>
              )
            })}
      </Section>
    </>
  )
}

export const query = graphql`
  {
    allStrapiTour(limit: 6, sort: { order: ASC, fields: sessions___start }) {
      ...StrapiTourConnectionFragment
    }
  }
`

export default CalendarYearPage
